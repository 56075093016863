
@import '../../../themes//color-pallet';
@import '../../../themes//mixins';

.sl-detail-carousel {
    margin: 30px;
}

.sl-carousel-img {
    margin: 30px 0px;
}

// .sl-landing-carousel-img {
//     height: 600px;
// }


.sl-platform-type {
    display: flex;
    justify-content: center;
    margin-right: 50px;

    .button-class {
        width: 120px;
        height: 40px;
        border-radius: 12px;
        background: linear-gradient(168.87deg, #0077B6 -54.73%, #023E8A 181.79%);
        color: #fff;
        display: flex;
        justify-content: center;
        padding: 6px;

        .icon {
            padding-right: 4px;
            font-size: 18px;
        }

        .sl-type {
            font-size: 18px;
            color: #FFFFFF;
            padding: 4px;
        }
    }
}

.sl-platform {

    .sl-platform-more-box {
        @include use-theme('background-color', 'color-landing-more-bg');
        border-radius: 25px;
        padding: 5px;
        text-align: left;
        width: 450px;
        margin: 30px;
    

        .title {
            margin: 0px 10px;
            font-size: 18px;
            font-weight: 600;
        }

        .info {
            margin: 0px 35px;
            font-size: 15px;
        }
    }

}


.sl-platform-info {
    font-size: 35px;
    text-align: left;
    margin: 0px 35px;

    span {
        line-height: 35px;
    }
}
