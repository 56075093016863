@import '../../../themes/_color-pallet.scss';
@import '../../../themes/_mixins.scss';


.sl-admin-page {
    background: #f7f7f7;
}

.sl-new-item-form-container {
    background-color: #fff;

    .sl-form-item {
        padding: 20px;
    }
}

.grey-border {

    width: 900px;
    @include use-theme('color','color-plain-text');
    
    
}

.question-title-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    column-gap: 10px;

}


.sl-new-container {
    @include use-theme('background-color','color-background');
    padding: 30px;
}