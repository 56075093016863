
@font-face {
    font-family: Proxina-Nova-Regular;
    src: url('Proxima Nova Regular.ttf');
}

@font-face {
    font-family: Proxina-Nova-Bold;
    src: url('Proxima Nova Bold.ttf');
}
