@import '../../../themes/_color-pallet.scss';
@import '../../../themes/_mixins.scss';

.sl-stepper-bread-crumb {
    @include use-theme(' border-color', 'color-border-grey');
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    padding-left: 30px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}
.step-btn {

    background-color: transparent;
    border-color: transparent;
    font-size: 15px;
}
.bs1-circle-fill {
    @include use-theme('color', 'color-blue');
    margin-right: 8px;
}
.b2-circle {

    @include use-theme('color', 'color-grey');
    margin-right: 8px;
}

.b2check-circle-fill {
   
    @include use-theme('color', 'color-green');
    margin-right: 5px;

}
.step-border {
  
  @include use-theme('border-color', 'color-border-black');
  border-width: 2px;
  width: 50px;
  margin: 0 5px 0 5px ;
}

.step-circle {

    border-radius: 10px;
    margin-right: 5px;
    height: 17px;
    width: 17px;
    display: inline-flex;
    background: #2266e3;
    color: #fff;
    font-size: 12px;
    padding: 0px 4px;
    //text-align: center;
    justify-content: center;
}
.step-nobg-circle {

    @include use-theme('color', 'color-grey');
    margin-right: 5px;
    border-radius: 10px;
    height: 15px;
    width: 15px;
    //background: #2266e3;
    //color: #fff;
    @include use-theme('border-color', 'color-grey');
    border-width: 1px;
    border-style: solid;
    font-size: 12px;
    padding: 0px 4px;
    text-align: center;
}
.chevron {
   @include use-theme('color', 'color-grey');
   width: 20px;
   height: 20px;
}
.chevron-bg {
    
    background-color: transparent;
    border-color: transparent;
}