@import '../../../themes//color-pallet';
@import '../../../themes//mixins';

.sl-dashboard {
    width: 100%;

}

.sl-search-container {
    @include use-theme('border-bottom-color', 'color-background');
    min-height: 75px;
    padding: 10px 10px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-left: 0px;

    input, .btn {
        margin: 5px 0px;
    }
}

.sl-welcome-note {
    padding: 0px 20px;

    .sl-title {
        @include use-theme('color', 'color-primary');
        font-weight: 900;
        font-size: 20px;
        display: block;
    }
    
    .sl-sub-text {
        @include use-theme('color', 'color-plain-text');
        font-size: 12px;
        display: block;
    }
}

.sl-user-location {
    padding: 20px;
}

.sl-create-contest-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    min-height: 50vh;

    .sl-create-contest-img {
        width: 150px;
        margin: 15px;
    }

    .sl-create-contest-overview {
        width: 450px;
        margin: 20px;

        .sl-create-contest-title {
            font-size: 20px;
            font-weight: 700;
            display: block;
        }

        .sl-create-contest-sub-txt {
            @include use-theme('color', 'color-plain-text');
            font-size: 14px;
    
        }
    }
}

.sl-dashbord-summary {
    border-left: 1px solid #eee;
    margin-left: 0px;
    padding: 0px;

    .sl-dashboard-summary-header {
        min-height: 75px;
        padding: 20px 0px;
        border-bottom: 1px solid #eee;
        margin: 0px 0px;
    }

    .sl-dashboard-summary-overview {
        @include use-theme('color', 'color-plain-text');
        display: flex;
        justify-content: center;
        padding: 0px 20px;
        text-align: left;

        img.sl-dashboard-summary-img {
            width: 150px;
        }

        .sl-dashboard-summary-title {
            @include use-theme('color', 'color-header-text');
            font-size: 16px;
            font-weight: bold;
            padding: 5px 10px;
            text-align: center;            
        }

        .sl-dashboard-summary-sub-txt {
            font-size: 12px;
            text-align: center;
            width: 80%;
        }

        .sl-dashboard-summary-info {
            font-size: 13px;
            margin-top: 30px;
        }
    }
}

.sl-contest-created-success-msg {

    @include use-theme('background-color', 'color-contest-created-msg-green-bg');
    border-bottom-color: transparent;
}

.modal-sub-text {
    
    @include use-theme('color', 'color-plain-text');
    font-size: 17px;

}
.contest-created-img {
   
    width: 90px;
    height: 70px;
    top: 13px;
    position: relative;
    margin-left: 42px;
}

.b1Circle {
    
    height: 55px;
    width: 55px;
}
.b2Circle {
    height: 55px;
    width: 65px;
}
.footer-modal {

    display: flex;
    justify-content: flex-start;
    column-gap: 20px;

}
