@import './themes//color-pallet';
@import './themes//mixins';

.sl-main-container {
    @include use-theme('background', 'color-background');
}

.no-data-container {
    width: 100%;
    height: 40px;
    padding: 0px 10px;
}
.sl-body-container {
    margin: 0px 3vw;
    background: #fff;
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: row;
}
.sl-content-container {
    height: calc(100vh - 120px);
    overflow: scroll;
}

.hover {
    cursor: pointer;
}

.sl-step-container {
    @include use-theme('background-color', 'color-background');
    padding: 30px;

    .step-header {
        font-size: 22px;
        @include use-theme('color','color-contest-title');
        font-weight: 800;
    
    }

    .step-details-info {
        @include use-theme('background-color', 'color-light-pink-contest-title-bg');
        padding: 10px;
        display: flex;
        flex: 1;
        flex-direction: row;    
    }

    .step-details-title  {
        display: flex;
        flex: 1;
        flex-direction: row;
        column-gap: 10px;

        .step-details-number {
            background-image: url('./images/gradient-circle.png');
            height: 25px;
            width: 25px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 100px;
            padding-left: 9px;
            align-self: center;
        
        }

        .step-title {
            @include use-theme('color', 'color-contest-title');
            font-weight: 500;            
            font-size: 17px;
            display: block;
        }

        .step-sub-text {
            @include use-theme('color', 'color-plain-text');
            font-size: 13px;
            display: block;
            padding-top: 1px;
            line-height: 17px;
        }
    
    }
}

/**
 * CSS for table and with create button and filter options
 */


 .search-container {
    width: 100%;
}


.sl-search-container {
    @include use-theme('border-bottom-color', 'color-background');
    min-height: 75px;
    padding: 10px 10px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-left: 0px;

    input,
    .btn {
        margin: 5px 0px;
    }
}

.sl-welcome-note {
    padding: 0px 20px;
    margin: 10px 0px;

    .sl-title {
        @include use-theme('color', 'color-header-text');
        font-weight: 900;
        font-size: 20px;
        display: block;
    }

    .sl-sub-text {
        @include use-theme('color', 'color-plain-text');
        font-size: 11px;
        display: block;

    }


}

.new-btn-container {
    @include use-theme('color', 'color-menu-text');
    width: 217px;
    height: 44px;
    top: 272px;
    left: 337.04px;
    background-color: #fafafa;
    margin: 0px 0px 0px 18px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;


}

.new-item-btn {
    border: none;
    background: none
}

.sl-filter {
    @include use-theme('color', 'color-menu-text');
    width: 79.55px;
    height: 44px;
    margin-top: -35px;
    padding: 0px, 8.55px, 0px, 0px;
    background-color: #fafafa;
    margin-left: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1717;


}

.pagination-page {
    width: 171.22px;
    height: 31.65px;
    // top: 10.71px;
    position: relative;
    left: 420.12px;
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;

}

.sl-list-contaner {
    padding: 15px;

    table {
        th {
            @include use-theme('color', 'color-menu-text');
            font-weight: 700;
            font-size: 13px;
            vertical-align: middle;
        }
        td {
            @include use-theme('color', 'color-font');
            font-size: 13px;
            vertical-align: middle;
        }
    }
}

.action-btn {
    width: 74px;
    height: 40px;
    top: 295.54px;
    left: 1247.12px;
    padding: 10px, 0px, 0px, 0px;
    border: none;
    font-size: 23px;

}

#dropdown-basic {

    width: 74px;
    height: 40px;
    top: 295.54px;
    left: 1247.12px;
    padding: 10px, 0px, 0px, 0px;
    border: none;
    font-size: 23px;
    background-color: #EBEBEB;
    color: #171717;
    display: flex;
    justify-content: center;
    align-items: center;


}

.drop-menu {
    width: 180px;
}

.edit,
.view,
.assign,
.view-applicants,
.delete {
    width: 180px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: medium;

}

.image1 {
    width: 27.02px;
    height: 24px;
    margin-right: 5px;
}


.page-btn {
    // background-color: blueviolet;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    border: 1px solid black;
    width: 20px;
    border-radius: 5px;



}

.pagination-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.cancel-button {
  
    @include use-theme('color' , 'color-menu-active-text');
    font-size: 14px;
    @include use-theme('border-color' , 'color-menu-active-text');
    @include use-theme('background-color','color-app-background');
    min-width: 80px;
    height: 33px;
    border-radius: 2px;
    
}

.submit-button {
    
    @include use-theme('color' , 'color-app-background');
    font-size: 14px;
    @include use-theme('border-color' , 'color-menu-active-text');
    background: linear-gradient(#023e8a ,#0077b6);
    min-width: 80px;
    height: 33px;
    border-radius: 2px;

}