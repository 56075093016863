@import '../../../themes//color-pallet';
@import '../../../themes//mixins';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}

.main-container {
    width: 100%;
    margin-left: 57px;

}

.rows-sl-web-col-one,
h1 {

    // height: 21rem;
    // font-size: 60px;
    // font-weight: 600;
    // line-height: 64px;
    // letter-spacing: -0.02em;
    // text-align: left;

}

.rows-sl-web-col-two {

    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 35px;


}

.rows-sl-web-col-three {

    width: 100%;
    height: 36.45rem;

}

.container-sl-main {

    margin-top: 100px;
}

.col-one {
    font-size: 60px;
    font-weight: 600;
    line-height: 84px;
    letter-spacing: -0.02em;
    text-align: left;


}

.col-two,
h5 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;

}

.col-two p {
    @include use-theme('color', 'color-plain-text');
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #605C68;


}

.col-four,
.our-sl-web-goal {
    @include use-theme('color', 'color-header-text');
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: 0em;
    text-align: left;



}

.ceo-name {
    @include use-theme('color', 'color-font');
    margin-left: 130px;
    bottom: 95px;
    position: relative;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;

}

.ceo-name-tag {
    @include use-theme('color', 'color-font');
    margin-left: 125px;
    bottom: 95px;
    position: relative;
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;


}

.question-container {
    @include use-theme('background-color', 'color-genre-txt-violet');
    top: 55px;
    position: relative;
    width: 100%;
    height: 38rem;
    display: flex;

}



.have-content-qstn {
    @include use-theme('color', 'color-app-background');
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    top: 150px;
    margin-left: 30px;
    float: left;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;


}

.sl-web-ask {
    @include use-theme('color', 'color-app-background');

display: flex;
justify-content: center;
align-items: center;
position: relative;
top: 180px;
margin-left: 30px;
float: left;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;




}

.have-a-horz-line {
    display: flex;
    top: 160px;
    position: relative;
    right: 120px;

}

.contact-us-btn {
    @include use-theme('background-color','color-app-background' );
    border-radius: 52px;
    padding: 16px, 24px, 16px, 24px;
    height: 4rem;
    width: 8rem;
    color: #FF5400;
    left: 5%;
    border: none;
    // background-color: cornflowerblue;
    top: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    


}


.picture-image {
  
    height:38rem;
    width:43%;
    left: 90px;
    float:left;
    position: relative;
    
}

.orange-color{
    @include use-theme('background-color','background-orange');
    width:23rem;
    height: 17rem;
    display: flex;
    flex-direction: column;
    float: right;
    margin-top: 30%;
    left: 2.5%;
    position: relative;
}
.image-curve{
    width: 25rem;
    height: 17rem;
    left: 28.5%;
    bottom: 0.1%;
    position: absolute;
}
.about-girl{
    width: 24rem;
    height: 29rem;
    left: 15%;
    bottom: 10%;
    position: absolute;
}

.second-row-third-column {
    // width: 100%;
    position: relative;
    max-width: 100%;
    background-color: #FFFFFF;
    height: 5rem;

    .copyright {
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
        justify-content: center;
        float: left;
        padding: 25px 0px 0px 35px;
    }

    .privacy { 
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
        text-decoration: none;
        padding: 25px 25px 0px 25px;
    }
}

