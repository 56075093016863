.right-panel-dialog {
    position: fixed;
    background-color: #FFF;
    border: 1px solid #cecece;
    overflow-y: scroll;
    display: block;
    top: 60px;
    bottom: 0;
    right: -450px;
    height: 100%;
    width: 450px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }
  
  .right-panel-dialog.show {
    right: 44px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }
  
  .close-header {
    width: 100%;
    text-align: end;
    background: #fafafa;

    button {
        text-align: right;
        border: none;
        background: none;
        margin: 5px 5px 0px 0px;
    }
  }