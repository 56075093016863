@import '../../../themes/_color-pallet.scss';
@import '../../../themes/_mixins.scss';

.tab-bar {
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    padding-left: 30px;
    @include use-theme(' border-color', 'color-border-grey');
    border-width: 1px;
    border-style: solid;
}
.tab {

    background-color: transparent;
    border-color: transparent;
    font-size: 15px;
}
.bs1-circle-fill {
    @include use-theme('color', 'color-blue');
    margin-right: 8px;
}
.b2-circle {

    @include use-theme('color', 'color-grey');
    margin-right: 8px;
}

.b2check-circle-fill {
   
    @include use-theme('color', 'color-green');
    margin-right: 8px;

}
.tab-middle-border {
  
  @include use-theme('border-color', 'color-border-black');
  border-width: 2px;
  width: 50px;
  margin: 0 5px 0 5px ;

}
.chevron {
   @include use-theme('color', 'color-grey');
   width: 20px;
   height: 20px;
}
.chevron-bg {
    
    background-color: transparent;
    border-color: transparent;
}
.question-one-bg {
  
    background-image: url('../../../images/gradient-circle.png');
    height: 25px;
    width: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 100px;
    padding-left: 9px;
    align-self: center;
      
}

.question-one {

    font-size: 14px;
    color: white;

}

.contest-one {
   
    @include use-theme('background-color', 'color-light-pink-contest-title-bg');
    width: 962px;
    padding: 10px;
    display: flex;
    flex: 1;
    flex-direction: row;

}

.contest-basics {
    
    @include use-theme('color', 'color-contest-title');
    font-weight: 500;
    
    font-size: 17px;
    display: block;

}

.contest-sub-text {
    
    @include use-theme('color', 'color-plain-text');
    font-size: 13px;
    display: block;
    padding-top: 1px;
    line-height: 17px;

}
.contest-box-sub-text {

    @include use-theme('color', 'color-plain-text');
    font-size: 11px;
    width: 400px;
    display: block;
    padding-top: 2px;
    

}

.question-title-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    column-gap: 10px;

}

.text-input {
    
    width: 400px;
    height: 45px;
    font-size: 14px;
    padding: 0 0 10px 5px;
    @include use-theme('color','color-contest-title')
}

.text-multi-input {
    
    width: 400px;
    height: 130px;
    font-size: 14px;
    padding: 5px;
    @include use-theme('color','color-contest-title')
}

.contest-queries-box-row {
 
    width: 900px;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    
}

.contest-heading {

    font-size: 22px;
    @include use-theme('color','color-contest-title');
    font-weight: 800;
}

.contest-queries {
    
    width: 319px;
}

.contest-basics-and-queries-container {

    padding: 30px;
    @include use-theme('background-color', 'color-background');

}

.input-text-length {

    position: absolute;
    pointer-events: 'none';
    right: 5px;
    top: 26px;
    font-size: 13px;
    @include use-theme('color', 'color-plain-text');
}

.input-multi-text-length {

    display: flex;
    flex: 1;
    width: 400px;
    justify-content: flex-end;
    font-size: 13px;
    //margin-bottom: 30px;
    //position: absolute;
    //right: 5px;
    //top: 110px;
    //padding-left: 20px;
    //font-size: 13px;
    @include use-theme('color', 'color-plain-text');
}
.input-multi-text-length-container {
    
    position: relative;
    top: -28px;
    right: 5px;

}

.grey-border {

    width: 900px;
    @include use-theme('color','color-plain-text');
    
    
}

.queries-container {

    @include use-theme('background-color', 'color-app-background');
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 962px;

}


.country-language-input {

    width: 160px;
    height: 40px;
    font-size: 14px;
    @include use-theme('border-color', 'color-plain-text');
}

.country-language-row {

    width: 400px;
    height: 130px;
    font-size: 14px;
    padding: 5px;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    

}

.label-txt {

    font-size: 14px;
    @include use-theme('color', 'color-contest-title');
}

.footer-row {
   
    display: flex;
    flex: 1;
    width: 962px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    @include use-theme('border-top-color', 'color-plain-text');
    
}


.save-img {

    @include use-theme('color' , 'color-green');
    @include use-theme('background-color' , 'color-yellow-pale-green');
    border-radius: 100px;
    width: 20px;
    height: 20px;

}

.save-img-txt-row {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    padding-top: 5px;
    
}
.save-txt {

    font-size: 14px;
    @include use-theme('color' , 'color-contest-title');
}
.add-another-market {
 
    color: #2266e3;
    border-color: transparent;
    background-color: transparent;
    font-size: 15px;
   
}
.selected-genre-language-country-container {
    flex-direction: row;
    flex: 1;
    display: flex;
    column-gap: 20px;
}
.selected-genre-language-country {

    font-size: 12px;
    @include use-theme('color','color-genre-txt-violet');
    @include use-theme('background-color', 'color-light-pink-contest-title-bg');
    padding: 8px;
    text-align: center;
}
 //second form

 .prize1-length {
   

    display: flex;
    flex: 1;
    width: 400px;
    justify-content: flex-end;
    font-size: 13px;
    @include use-theme('color', 'color-plain-text');

 }
 .prize1-length-container {
    
    top: 26px ;
    position: absolute;

 }

 .prize2-length {
   
    display: flex;
    flex: 1;
    width: 400px;
    justify-content: flex-end;
    font-size: 13px;
    @include use-theme('color', 'color-plain-text');

 }
 .prize2-length-container {
    
    margin-top: 28px ;
    position: absolute;

 }

 .prize3-length {
   
    display: flex;
    flex: 1;
    width: 400px;
    justify-content: flex-end;
    font-size: 13px;
    @include use-theme('color', 'color-plain-text');

 }
 .prize3-length-container {
    
    margin-top: 28px ;
    position: absolute;

 }
 .prize-number-flex {

   
    display: flex;
    flex-direction: row;
    flex: 1;
    column-gap: 6px;
    align-items: center;
 
}

.prize-text-box {

    width: 392px;
    height: 45px;
    font-size: 14px;
    padding: 0 0 10px 5px;
    @include use-theme('color','color-contest-title')

}

.entry-rules-box {
    
    width: 392px;
    height: 80px;
    font-size: 14px;
    padding: 5px;
    @include use-theme('color','color-contest-title')

}

.entry-rules-input-count-length {
 
    position: absolute;
    width: 392px;
    padding-right: 8px;
    display: block;
    text-align: right;
    pointer-events: 'none';
    //right: 15px;
    top: 60px;
    font-size: 13px;
    @include use-theme('color', 'color-plain-text');
}

//step3 

.start-end-date {
    
    width: 400px;
    height: 45px;
    font-size: 14px;
    @include use-theme('color','color-contest-title')
}
.start-end-date-text {

    width: 80px;

}
.deadline-fees-input {

    width: 160px;
    height: 40px;
    font-size: 14px;
}
.deadline-fees-row {

    width: 400px;  
    font-size: 14px;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;

}
.checkbox-fee-text {
    
    @include use-theme('color', 'color-plain-text');
    font-size: 15px;
    padding-left: 5px;

}
.link-account {

   // @include use-theme('background-color', 'color-app-background');
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: 10px;
    align-items:flex-start;
    justify-content: flex-start;
    width: 400px;

}
.contact-details-text {

    font-size: 14px;
    width: 185px;
    height: 30px;
    @include use-theme('color','color-contest-title');

}
.terms-and-conditions-input-length-count {

    position: absolute;
    pointer-events: 'none';
    right: 15px;
    top: 85px;
    padding-left: 20px;
    font-size: 13px;
    @include use-theme('color', 'color-plain-text');

}
.terms-show-check-link {
    width: 400px;  
    font-size: 16px;
    display: flex;
    flex: 1;
    flex-direction: row;
    column-gap: 30px;
    margin-bottom: 5px;
}
.review-submit {

        @include use-theme('color' , 'color-app-background');
        font-size: 14px;
        @include use-theme('border-color' , 'color-menu-active-text');
        background: linear-gradient(#023e8a ,#0077b6);
        width: 150px;
        height: 33px;
        border-radius: 2px;     
}
.summary-row {

   display: flex;
   flex-direction: row;
   justify-content: space-between;
   width: 940px;
   padding: 15px;
   align-items: center;
}