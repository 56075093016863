
@import '../../../themes//color-pallet';
@import '../../../themes//mixins';

.login-modal {
    max-height: 540px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    .login-img {
        height: 415px;
        margin-top: -16px;

        .login-bg-img {
            max-width: 350px;
        }
    }

    .login-container {

        margin: 50px 0px;

        .greet {
            font-size: 22px;    
        }

        .info {
            @include use-theme('color', 'color-primary');
            font-size: 15px;
        }

        .label {
            font-size: 14px;
            margin: 10px 0px 0px 0px;
            color: #2E2E2E;
        }

        .input {
            @include use-theme('background-color', 'color-background');
            height: 40px;
            border-radius: 1px;
            padding: 12px 8px;
            border: 1px solid #DDDDDD;
            width: 100%;
        }

        .forget-password {
            text-underline-offset: #DDDDDD;
            font-size: 12px;
        }

        .terms {

            .temrs-privacy {
                background-color: #EBEFFF;
                font-size: 10px;
            }
        }
        
        .check-box {
            width: 22px;
            height: 22px;
            border: 1px #DDDDDD;        
        }
        
        .btn-container {
            margin: 30px 0px;

            .login-btn {
                width: 100px;
                height: 35px;
                border-radius: 50px;
                background: linear-gradient(168.87deg, #0077B6 -54.73%, #023E8A 181.79%);
                color: white;
                border: 1px solid #023E8A;
            }
    
            .signup-btn {
                width: 100px;
                height: 35px;
                border-radius: 50px;
                background: #fff;
                color: #023E8A;
                border: 1px solid #023E8A;
            }

        }        
    }
}

.close-icon {
    font-size: 25px;
    position: absolute;
    right: 10px;
    top: 5px;
}


.corner-image {
    width: 100px;
    height: 80px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.model-body {
    background: #EBEFFF;
}

.model-header {
    background: #EBEFFF;
}