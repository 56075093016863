@import '../../../themes//color-pallet';
@import '../../../themes//mixins';
// @import '../../../images/icons8-right-arrow-30.png';
// @import '../../../images/icons8-down-arrow-64.png';


.right-panel-main {
    width: 600px;
    height: 924.57px;
    top: -2.3px;
    left: 834.94px;
    border: #FAFAFA;

    display: flex;
    margin-left: 40%;

}

.details {
    width: 600px;
    height: 211.16px;
    top: -2.3px;
    left: 834.94px;
    border: #FAFAFA;
}

.contest-info {

    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #fafafa;

    .contest-name {
        font-size: 24px;
        font-weight: 700;
        text-align: left;
        color: #000000;
    }
    
    .sl-genre {
        gap: 8px;
        display: flex;    
    }
    
    .genre {
        @include use-theme('background-color', 'color-light-pink-contest-title-bg');
        background-color: #F4E2FF;
        color: #3C096C;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        //width: 60px;
        height: 24px;
        padding: 10px;
        border-radius: 2px;
    }
    
}

.sl-key {
    font-size: 15px;
    width: 90px;
    display: inline-block;
    color: #000;
}

.sl-value {
    font-size: 14px;
    color: #808080;
}

.contest-img {
    height: 100px;
    border-radius: 15px;
    max-width: 150px;
}


.btn-arrow {
    width: 30px;
    height: 20px;

}

.contest-basics-btn {
    width: 200px;
    height: 45px;
    top: 70px;
    position: relative;
    margin: 10px 0px 0px 25px;

}

.accordion-btn {
    border: none;
    border-radius: 0px;
}

.contest-info-more {

     .card-header {
        background-color: #fff;

        p {
            margin-top: 0;
            margin-bottom: 0.3rem;        }
     }
}

.accor-name {
    width: 104px;
    height: 22px;
    top: 221.7px;
    left: 913.94px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #3C096C;

}

.card-body {
    width: 100%;

    .contest-details {
        display: flex;
        justify-content: flex-start;
    }

    .contest-key {
        display: inline-block;
        width: 36%;
        font-size: 14px;
        font-weight: 400;
        color: #605C68;
        padding: 10px 20px;    
    }

    .contest-value {
        font-size: 14px;
        font-weight: 400;
        width: 60%;
        text-align: start;
        display: inline-block;
        padding: 10px 20px;
    }

    .contest-description {
        width: 125px;
        height: 17px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        margin: 10px 0px 0px 20px;
        color: #605C68;


    }

    .para-of-contest {
        width: 341.17px;
        height: 160px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #10002B;
        margin: -15px 0px 0px 150px;

    }

    .btn-showmore {
        width: 73px;
        height: 20px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #2266E3;
        border: none;
        background: #FFFFFF;

    }

    .owner-contest {
        width: 45px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #605C68;
        margin: 5px 0px 0px 10px;


    }

    .owner-image, .collaborator-img {
        width: 25px;
        height: 25px;
        margin: 0px 5px;
    }

    .owner-name {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #10002B;
 
    }

    .collaborators-sl {
        width: 86px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #605C68;
        margin: 15px 0px 0px 10px;
    }

    .collaborators-images {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        width: 21.72px;
        height: 24px;
        left: 151px;
        left: 151px;
        margin: -20px 0px 0px 150px;
    }

    .status-sl {
        width: 42px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #605C68;
        margin: 20px 0px 0px 10px;
    }

    .winners-sl {
        width: 54px;
        height: 20px;
        top: 626.72px;
        left: 916px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #605C68;
        margin: 20px 0px 0px 10px;
    }
}


.prize-rules {
    .pr-title {
        @include use-theme('color','color-primary');
        font-size: 16px;
        font-weight: 500;
        display: block;
        margin: 5px;
    }
    .pr-details {
        font-size: 14px;
        display: block;
        margin: 5px;
    }
}

.contest-info-title {
    margin: 10px;
    display: block;
    font-weight: 400;
}

.contest-applicants {
    margin: 0px 15px;

    table {
        th {
            @include use-theme('background-color','color-light-pink-contest-title-bg');
            @include use-theme('color','color-primary');
            text-align: center;
            text-transform: uppercase;
        }

        td {
            text-align: center;
        }
    }
}

.user-profile {
    height: 30px;
    width: 30px;
    background-color: #ccc;
    border-radius: 25px;
}
.sl-story-info {
    margin: 0px 0px;

    .sl-story-info-content {
        margin: 0px 10px;
    }
}

.story-text {
    @include use-theme('background-color','color-background');
    border-radius: 5px;
}

.sl-story-grading {
    margin: 0px 20px;
    margin-top: 10px;

    .sl-grading-title {
        font-weight: 700;
        font-size: 13px;
        display: block;
        color: #4a4949;
    }

    .sl-selection-option {
        margin: 10px 0px;

        .sl-option-text {
            color: #08155b;
            font-weight: 700;
            font-size: 14px;
            font-weight: 800;
            margin-left: 20px;
            margin-top: -23px;
            display: block;
        }

        .sl-option-info {
            color: #74707b;
            font-size: 12px;
            margin-left: 20px;
        }
    }

    .sl-section-comments {
        textarea {
            width: 100%;
            height: 80px;
        }
    }

    .sl-section-actions {
        text-align: right;
        
        .btn {
            margin: 0px 5px;
        }
    }

}

.sl-story-info-content {
    overflow-y: auto;
    min-height: 72vh;
    
    .user-info {
        display: flex;
        margin: 0px 20px;

        .story-banner {
            height: 60px;
            width: 60px;
            margin: 0px 10px;
            border-radius: 30px;
            background-color: #ccc;
            border: 1px solid #ccc;
        }
        .story-title {
            font-family: Proxina-Nova-Bold;
            display: block;
            font-weight: 700;            
            font-size: 14px;
        }

        .story-author {
            display: block;
            font-size: 14px;

            b {
                font-family: Proxina-Nova-Bold;
                font-weight: 700;
            }
        }

        .story-author-info {
            font-size: 14px;
        }
    }
}

.contest-info-links {
    margin: 5px 0px;
    height: 35px;
    border-bottom: 1px solid #ccc;

    .link {
        padding: 0px 4px;
        color: #4c4b4a;
        text-decoration: none;
        font-size: 14px;
    }

}