@import '../../../themes/_color-pallet.scss';
@import '../../../themes/_mixins.scss';


.container {
    padding: 40px;
}
.profile-summary {

    font-size: 25px;
    @include use-theme('color', 'color-border-black');
    font-weight: 800;    
}
.logo-text-container {

    bottom: 22px ;
    position: relative;
    padding-left: 50px;
}
.wallpaper {
   
    width: 970px;
    height:190px;
}
.logo {
    width: 140px;
    height: 140px;
    border-radius: 15px;
    padding: 6px;
    
}
.logo-container {
    
    @include use-theme('background-color','color-light-pink-contest-title-bg');
    border-radius: 15px;
    width: 140px;
}
.media-icon {

    width: 35px;
    height: 35px;
}
.media-icon-row {

    display: flex;
    column-gap: 20px;
}
.address-text {
 
    font-size: 16px;
    @include use-theme('color', 'color-grey');
    font-weight: 700;
}
.members-text {

    font-size: 16px;
    @include use-theme('color', 'color-border-black');
    font-weight: 600;
}
.host-name {
 
    font-size: 20px;
    @include use-theme('color', 'color-border-black');
    font-weight: 600;
}
.pencil-btn {
    
    position: absolute;
    margin-top: 15px
}
.pencil-btn-container {
    
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-self: flex-end;
    width: 960px;
}
.details-container{

    @include use-theme('background-color', 'color-background');
    
}
.user-profile {
    padding-left: 20px;
}
.user-profile-box {
    @include use-theme('border-color', 'color-border-grey');
    border-style: solid;
    border-width: 1px;
    width: 766px;
    padding-top: 10px;
    padding-bottom: 10px;
    @include use-theme('background-color', 'color-app-background');
}
.box-row {

    display: flex;
    justify-content: space-between;
    align-items: center;
    @include use-theme('color', 'color-plain-text');
    font-size: 15px;
    padding: 0px 10px 0px 10px;
}
.row-headings {
    width: 150px;
    @include use-theme('color', 'color-plain-text');
    
}
.profile-img {
   width: 25px;
   height: 25px;
   border-radius: 100px;
   
}
.border-grey {
    
    @include use-theme('color', 'color-grey');
}
.question-circle {
    width: 15px;
    height: 15px;
}
.social-img {
    width: 25px;
    height: 25px;
}
.contact-row {
    
    padding: 0px 10px 0px 20px;
    display: flex;
    align-items: center;
    font-size: 15px; 

}
.contact-box {
    padding-top: 10px;
    padding-bottom: 10px;
    @include use-theme('border-color', 'color-border-grey');
    border-style: solid;
    border-width: 1px;
    width: 400px;
    @include use-theme('background-color', 'color-app-background');
}
.profile-name {
  padding: 0px 10px 0px 10px;
  @include use-theme('color', 'color-menu-active-text');
}
.email-id-phone {
    padding: 0px 10px 0px 10px;
    @include use-theme('color', 'color-blue-profile-summary');
}
.grader {
    padding-left: 10px;
    @include use-theme('color', 'color-border-black');
}
.contact-name {
    padding-left: 10px;
    @include use-theme('color', 'color-header-text');
  }
.contact-info {
    @include use-theme('color', 'color-blue');
}
.user-profile-label {

    font-size: 16px;
    @include use-theme('color', 'color-border-black');
    padding: 30px 0px 5px 10px;
    
}
.edit-container {
    @include use-theme('background-color', 'color-app-background');
    border-width: 1px;
    border-style: solid;
    @include use-theme('border-color', 'color-border-grey');
    padding: 20px;
    border-radius: 20px;
}
.edit-images-container {
    @include use-theme('border-color', 'color-light-blue');
    border-width: 2px;
    border-style: solid;
    padding: 20px;
}
.edit-user-profile {
    @include use-theme('border-color', 'color-light-pink-contest-title-bg');
    border-radius: 20px;
    border-width: 5px;
    border-style: solid;
    padding: 20px;
}
.edit-text-box {
    border-width: 1px;
    border-style: solid;
    padding: 10px;
    @include use-theme('color', 'color-menu-active-text');
    @include use-theme('border-color', 'color-border-grey');
}
.save-cancel-btn-row {
    display: flex;
    justify-content: flex-end;
    column-gap: 40px;
    padding: 20px;

}