@import '../../../themes//color-pallet';
@import '../../../themes//mixins';


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;   
}
.container-your-thought-guard {
    background: #F4E2FF;
    @include use-theme('background-color', 'bg-color');
    max-width: 100%;
    height: 100%;
    .first-row {
        width: 100%;
        padding: 50px 0px 20px;        
        .first-column {
            width: 80%;
            min-height: 8.375rem;
            .heading-text {
                font-family: 'Proxina-Nova-Bold';
                font-size: 30px;
                @include use-theme('color', 'color-header-text');
            }
        }
        .second-column {
            width: 100%;
            .preserving {
                font-size: 26px;
            }
        }
    }

    .second-row {
        width: 100%;
        padding: 0px 50px;
        .para-era, .world-era {
            @include use-theme('color','color-plain-text');
            font-size: 20px;
            display: block;
            padding: 10px 0px;
        }
        .para-storyline, .storyline-trusted {
            font-size: 20px;
            display: block;
            @include use-theme('color','color-plain-text');
        }
        .read-content {
            padding: 15px 0px 0px;
            font-size: 25px;
            text-align: left;
            display: block;            
            @include use-theme('color','background-orange'); 
        }
        .digital-mindspace {
            font-size: 20px;
            @include use-theme('color','color-plain-text');
            display: block;
            padding: 10px 0px;
        }

        .read-more {
            padding: 15px 0px 0px;
            font-size: 25px;
            text-align: left;
            display: block;            
            @include use-theme('color','background-orange');
        }

    }
}

.leftimage {
    width: 100%;
    border-radius: 12px;
}
.rightimage {
    width: 100%;
    border-radius: 12px;
}
.textcolor {
    @include use-theme('color','color-primary');
    font-size: 24px;
    font-weight: 700;
    background-color: none;
}
.social-media-logos{
    width: 50%;
    height: 10%;
    padding-top: 30px;
    .google-play{
        width: 30%;
        
    }
    .app-store{
        width: 30%;
        margin: 10px 10px 10px 10px;    
    }
    .facebook{
        width: 10%;
    }
    .twitter{
        width: 10%;
    }
    .instagram{
        width: 10%;
    } 
}
.second-row-third-column {
    @include use-theme('background-color', 'color-app-background');
    position: relative;
    max-width: 100%;
    height: 5rem;

    .copyright {
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
        justify-content: center;
        float: left;
        padding: 25px 0px 0px 35px;
    }
    
    .privacy { 
        @include use-theme('color','color-border-black');
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
        text-decoration: none;
        padding: 25px 25px 0px 25px;
    }
}

