@import '../../../themes/color-pallet';
@import '../../../themes//mixins';
@import '../../../fonts//fonts.scss';

.header {
    @include use-theme('background-color', 'color-app-background');
    @include use-theme('border-bottom-color', 'color-background');
    height: 60px;
    padding: 0px 8%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    width: 100%;
    z-index: 999;
}

.app-name {
    font-size: 30px;
    font-weight: 900
}

.logo-img {
    margin: 0px 10px;
}

.primary-txt-color {
    @include use-theme('color', 'color-primary');
}

.login-link {
    @include use-theme('color', 'color-primary');
    text-decoration: none;
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
}

.try-btn {
    width: 120px;
    height: 40px;
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
    border-radius: 50px;
    background: linear-gradient(315deg, #889197 -23.9%, #3C096C 35.06%, #3C096C 54.06%, #FF5400 166.94%);
}