@import '../../../themes//color-pallet';
@import '../../../themes//mixins';

.sl-left-nav {
    @include use-theme('border-right-color', 'color-background');
    width: 22%;
    max-width: 210px;
    height: 100vh;
    border-right-width: 1px;
    border-right-style: solid;

    .account-banner > img {
        height: 75px;
        width: 100%;
    }

    .account {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #ccc;
        margin-bottom: 5px;

        .account-logo > img {
            @include use-theme('border-color', 'color-background');
            height: 50px;
            width: 50px;
            border-radius: 10px;
            border-width: 2px;
            border-style: solid;
            position: relative;
            top: -6px;
            left: 5px;
        }

        .account-details {
            margin-left: 15px;
            margin-top: 10px;
            
            .account-name {
                @include use-theme('color', 'header-text');
                margin-bottom: 0px;
                display: block;
                line-height: 22px;
                font-size: 20px;
                margin-top: 3px;
                font-weight: 800;
            }

            .account-members {
                font-size: 10px;
                line-height: 10px;
            }
        }
    }

    ul.sl-v-nav {
            li {
                display: flex;
                align-items: center;
                padding: 0px 10px;
            }
        }
}


